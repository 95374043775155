<template lang="pug">
div(style="height: 70%; width: 100%;" align="center" justify="center")

  div(style="padding:10px" )
    v-row()
      v-col(cols="3")
        v-select.mt-1(
          v-model="selectedCenario"
          placeholder="Selecione uma visão"
          :items="configCenarios"
          item-text="text"
          item-value="value"
          outlined
          hide-details
          dense
          label="Visão"
          @change="getCsApiData(selectedCenario)"
        )
      v-col(cols="9" class="text-left d-flex")
        v-btn(
          style="margin-top: 2px;"
          icon
          outlined
          color="primary"
          @click="getCsApiData(selectedCenario, true)"
        ) 
          v-icon mdi mdi-refresh

        div(style="justify-content: left; padding: 15px; padding-left:0; text-align: left" font-size="20px" class="ml-6")
          span {{ description }}
    v-row
      v-col(cols="3" class="items-start")
        v-select(
          v-model="cenarioTemp"
          :items="cenariosList"
          item-text="nome"
          item-value="id"
          return-object
          outlined
          label="Cenários"
          placeholder="Selecione ou crie um cenário"
          hide-details
          dense
          @change="selectCenario"
        )

          template(v-slot:prepend-item)
            v-list-item(
              dense,
              ripple,
              @mousedown.prevent
              @click="newCenario()"
            )
              v-list-item-action
                v-icon mdi mdi-plus
              v-list-item-content
                v-list-item-title Novo Cenário
            v-divider 
      
      v-col(cols="6" class="text-left align-start")
        v-btn(
          v-if="isSuperUser"
          :disabled="cenarioTemp.id <= 0"
          style="margin-top: 2px;"
          outlined
          color="primary"
          @click="cloneMode = !cloneMode; dialogCadastroCenarios = true"
        ) 
          v-icon(left) mdi mdi-text-box-multiple-outline
          span Clonar
      
      v-col(cols="3" class="text-right align-start")
        v-btn(
          v-if="isSuperUser"
          :disabled="cenarioTemp.id <= 0"
          style="margin-top: 2px;"
          outlined
          color="primary"
          @click="dialogCadastroCenarios = true"
        ) 
          v-icon(left) mdi mdi-pencil-outline
          span Atualizar

        v-btn(
          v-if="isSuperUser"
          :disabled="cenarioTemp.id <= 0"
          style="margin-top: 2px;"
          class="ml-2"
          outlined
          color="error"
          @click="dialogExcluir = true"
        ) 
          v-icon(left) mdi mdi-delete-outline
          span Deletar

    //- v-row
    //-   v-col(cols="12" class="text-left align-start ma-0 pa-0 pl-3")
    //-     span {{ cenarioTemp.descricao }}

    v-row
      PivotTable(
        v-if="visible"
        v-model="configRetorno",
        :dataset="dados"
        :disable-col-total="false"
        :disable-row-total="false"
        :rows="configSelected.configuracao.rows",
        :cols="configSelected.configuracao.cols",
        :vals="configSelected.configuracao.vals",
        :aggregator-name="configSelected.configuracao.aggregatorName",
        :renderer-name="configSelected.configuracao.rendererName",
        :rowOrder="configSelected.configuracao.rowOrder",
        :colOrder="configSelected.configuracao.colOrder",
        :value-filter="configSelected.configuracao.valueFilter"
        class="ma-3"
      )    

  //- Dialog com titulo e descrição para gravar o cenário
  v-dialog(
    v-model="dialogCadastroCenarios",
    v-if="dialogCadastroCenarios",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 {{ cloneMode ? "Clonar" : configSelected.id === 0 ? "Incluir" : "Atualizar" }} Cenário
      v-card-text
        v-text-field.mt-1(
          label="Nome do Cenário",
          v-model="configSelected.nome",
          outlined,
          maxlength="80",
          counter,
          dense,
          required,
          :rules="rules"
        )
        v-textarea.mt-2(
          label="Descrição do Cenário",
          v-model="configSelected.descricao",
          outlined,
          maxlength="200",
          counter,
          dense,
          required,
          :rules="rules"
        )
      v-card-actions.pr-0.pt-0.mt-0
        v-spacer
        v-btn(color="primary", outlined, @click="cancelaCadastroCenarios()") Cancelar
        v-btn(color="primary", outlined, @click="gravarCenario()") 
          span {{ cloneMode ? "Clonar" : configSelected.id === 0 ? "Salvar" : "Atualizar" }}

  //- Dialog para exclusão de cenário
  v-dialog(
    v-model="dialogExcluir",
    v-if="dialogExcluir",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 Deletar Cenário
      v-card-text
        h5 Tem certeza que deseja deletar o cenário - {{ configSelected.nome }}?
      v-card-actions.pr-0.pt-0.mt-0
        v-spacer
        v-btn(color="primary", outlined, @click="dialogExcluir = false") Cancelar
        v-btn(color="error", outlined, @click="deletarCenario") Deletar
</template>

<script>
import api from '@/api/csapi';
import { mapMutations } from 'vuex';
import csapi from "@/api/csapi";

// import visao_clientes from '@/assets/agrupar_por_cliente.json';
// import visao_usuario from '@/assets/agrupar_por_usuario.json';

export default {
  components: {},
  data() {
    return {
      isSuperUser: false,
      dialogCadastroCenarios: false,
      dialogExcluir: false,
      cloneMode: false,
      selected: 'Produtos Web/Mobile - Consulta',
      selectedSend: false,
      dados: [],
      cenarioTemp: {
        id: 0,
        name: "",
      },
      cenariosList: [],
      locales: {},
      configCenarios: [
        { value: 1, text: 'Agrupar por Usuários' },
        { value: 2, text: 'Agrupar por Clientes' }
      ],
      selectedCenario: 2,
      description: '',
      visible: true,
      configRetorno: {},
      initConfig: {
        visao: 2,
        rowOrder: "key_a_to_z",
        colOrder: "key_a_to_z",
        valueFilter: {},
        rows: [],
        cols: [],
        rendererName: "Tabela",
        aggregatorName: "Soma",
        aggregators: {},
        vals: [],
      },
      configSelected: {
        id: 0,
        nome: "",
        descricao: "",
        funcionalidade: 'produtos_web_mobile',
        configuracao: {
          visao: 2,
          rowOrder: "key_a_to_z",
          colOrder: "key_a_to_z",
          valueFilter: {},
          rows: [],
          cols: [],
          rendererName: "Tabela",
          aggregatorName: "Soma",
          aggregators: {},
          vals: [],
        },
      },
      rules: [(v) => !!v || "Campo obrigatório"],
    }
  },

  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    permissoesAcesso() {
      const roles = JSON.parse(JSON.stringify(this.$store.state.auth.user.roles));
      const role = roles.filter((role) => role.projectid == 257)

      if (role.length == 0) {
        this.isSuperUser = false
      } else {
        const permissao = role.filter((permission) => permission.id == 4)
        if (permissao.length > 0) {
          this.isSuperUser = true
        }
      }
    },

    async getCsApiData(cenario, reload = false) {
      const url = cenario === 1 ? '/v1/usuario/consulta' : '/v1/cliente/consulta';

      this.showLoader('Carregando Visões...');
      this.description = this.selectedCenario === 2 ? 'Este grupo relaciona clientes e empresas por produtos' : 'Esse grupo relaciona usuários a produtos';
      this.visible = false;
      
      try {
        const response = await api.get(url);
        this.dados = response.data;

        this.visible = true;
      } catch (error) {
        this.$toast.error("Problema ao carregar os dados, tentar novamente");
      } finally {
        if (!reload) this.getCenarios();
        this.hideLoader();
      }
    },

    selectCenario(cenario) {
      this.configSelected = cenario
    },

    async getCenarios(id) {
      this.showLoader('Carregando cenários...');
      try {
        const link = `/v1/cenariosconfig?funcionalidade=produtos_web_mobile`;
        const res = await csapi.get(link);

        if (res.status.toString().startsWith("2")) {
          this.cenariosList = res.data.filter((cen) => cen.configuracao.visao === this.selectedCenario);
        }
    
        if (id !== undefined) {
          this.cenarioTemp = this.cenariosList.find((cen) => cen.id === id);
          this.configSelected = this.cenariosList.find((cen) => cen.id === id);
        } else if (this.cenariosList.length > 0) {
          this.cenarioTemp = this.cenariosList[0];
          this.configSelected = this.cenariosList[0];
        } else if (this.cenariosList.length === 0) {
          this.gravarCenario(true);
        }
        
      } catch (e) {
        console.error("e", e);
      } finally {
        this.hideLoader();
      }
    },

    async gravarCenario(notScenario = false) {
      if (notScenario) {
        this.configSelected.nome = "Cenário padrão";
        this.configSelected.descricao = "Para começar a montar o seu cenário, arraste os campos para as áreas de linhas e colunas. Em seguida selecione o tipo de visualização (Tabelas ou Gráficos) e a forma de agregação (Soma, Média, Contagem, etc). Por fim, clique em atualizar para gravar essa nova configuração.";
      }

      let res = null;
      const link =
        this.configSelected.id === 0 || this.cloneMode
          ? `/v1/cenariosconfig`
          : `/v1/cenariosconfig/${this.configSelected.id}`;

      this.configSelected.configuracao = {...this.configSelected.configuracao, ...this.configRetorno}

      this.showLoader('Gravando registro...');
      try {
        if (this.cloneMode) this.configSelected.id = 0;
        this.configSelected.configuracao.visao = this.selectedCenario;

        res =
          this.configSelected.id === 0 || this.cloneMode
            ? await csapi.post(link, this.configSelected)
            : await csapi.put(link, this.configSelected);
        
        if (res.status.toString().startsWith("2")) {
          this.$toast.success(
            this.cloneMode
              ? "Cenário clonado com sucesso"
              : this.configSelected.id === 0
              ? "Cenário gravado com sucesso"
              : "Cenário atualizado com sucesso"
          );
        }

        res = res.data !== '' ? res.data.id : this.configSelected.id;

        this.nomeForFind = this.configSelected.nome;
      } catch (e) {
        console.error("e", e);
        this.$toast.error(
          "Problema ao gravar/atualizar o cenário, consulte o suporte"
        );
      } finally {
        this.cloneMode = false;
        this.dialogCadastroCenarios = false;
        this.hideLoader();
        this.getCenarios(res);
      }
    },

    async deletarCenario() {
      this.dialogExcluir = false;
      this.showLoader('Deletando cenário...');
      try {
        const link = `/v1/cenariosconfig/${this.configSelected.id}`;        
        const { status } = await csapi.delete(link);

        if (!status.toString().startsWith("2")) {
          throw new Error('Problema ao deletar o cenário, tentar novamente');
        }

        this.$toast.success("Cenário deletado com sucesso");
      } catch (e) {
        this.$toast.error("Problema ao deletar o cenário, consulte o suporte");
      } finally {        
        this.configSelected = {
          id: 0,
          nome: "",
          descricao: "",
          configuracao: JSON.parse(JSON.stringify(this.initConfig)),
          funcionalidade: 'produtos_web_mobile'
        };
        this.getCenarios();
        this.hideLoader();
      }
    },

    cancelaCadastroCenarios() {
      this.dialogCadastroCenarios = false;
      this.cloneMode = false;

      this.getCenarios();
    },

    newCenario() {
      this.nomeForFind = this.configSelected.nome;

      this.configSelected = {
        id: 0,
        nome: "",
        descricao: "",
        configuracao: JSON.parse(JSON.stringify(this.initConfig)),
        funcionalidade: 'produtos_web_mobile'
      };

      this.cloneMode = false;
      this.dialogCadastroCenarios = true;
    },
  },

  async mounted() {
    this.isControl(true);
    this.setTitle('Produtos Web/Mobile - Consulta');
    this.permissoesAcesso()
    this.getCsApiData();    
  }
}

</script>
